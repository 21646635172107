import fetch from '../utils/fetch'

export function getshequdianDetail (id) {
  return fetch({
    url: '/shequdian/' + id,
    method: 'get'
  })
}

export function getshequdianSetting () {
  return fetch({
    url: '/shequdian/order/setting',
    method: 'get'
  })
}

export function shequdianSetting (query) {
  return fetch({
    url: '/shequdian/order/setting',
    method: 'post',
    params: query
  })
}
export function shequdianCreate (query) {
  return fetch({
    url: '/shequdian',
    method: 'post',
    params: query
  })
}

export function shequdianUpdate (id, query) {
  return fetch({
    url: '/shequdian/' + id,
    method: 'put',
    params: query
  })
}

export function shequdianDelete (id) {
  return fetch({
    url: '/shequdian/' + id,
    method: 'delete'
  })
}

export function getshequdianList (query) {
  return fetch({
    url: '/shequdian/list',
    method: 'get',
    params: query
  })
}


export function changeStatus (query) {
  return fetch({
    url: '/shequdian/changestatus',
    method: 'post',
    params: query
  })
}

export function changeOpenDeliveryPrice (query) {
  return fetch({
    url: '/shequdian/changeOpenDeliveryPrice',
    method: 'post',
    params: query
  })
}


export function getshequdianOrderTotalList (query) {
  return fetch({
    url: '/shequdian/ordertotal/list',
    method: 'get',
    params: query
  })
}
export function getshequdianOrderList (query) {
  return fetch({
    url: '/shequdian/order/list',
    method: 'get',
    params: query
  })
}

export function orderExport (query) {
  return fetch({
    url: '/shequdian/order/export',
    method: 'GET',
    params: query
  })
}
export function orderDayTotalExport (query) {
  return fetch({
    url: '/shequdian/orderdaytotal/export',
    method: 'GET',
    params: query
  })
}
export function orderTotalExport (query) {
  return fetch({
    url: '/shequdian/ordertotal/export',
    method: 'GET',
    params: query
  })
}
export function orderTotalCaiGouExport (query) {
  return fetch({
    url: '/shequdian/ordercaigou/export',
    method: 'GET',
    params: query
  })
}
export function orderTotalCaiGouExportTag (query) {
  return fetch({
    url: '/shequdian/ordercaigoutag/export',
    method: 'GET',
    params: query
  })
}


export function getshequdianListOfflineOrderBind (query) {
  return fetch({
    url: '/shequdian/listOfflineBindOrder',
    method: 'post',
    params: query
  })
}

