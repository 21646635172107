import fetch from '../utils/fetch'

export function getyewuyuanDetail (id) {
  return fetch({
    url: '/yewuyuan/' + id,
    method: 'get'
  })
}

export function yewuyuanCreate (query) {
  return fetch({
    url: '/yewuyuan/create',
    method: 'post',
    params: query
  })
}

export function yewuyuanUpdate (id, query) {
  return fetch({
    url: '/yewuyuan/update/' + id,
    method: 'put',
    params: query
  })
}

export function yewuyuanDelete (id) {
  return fetch({
    url: '/yewuyuan/' + id,
    method: 'delete'
  })
}

export function getYewuyuanList (query) {
  return fetch({
    url: '/yewuyuan/list',
    method: 'get',
    params: query
  })
}
export function yewuyuanCheck (id, params) {
  return fetch({
    url: `/yewuyuan/check/${id}`,
    method: 'post',
    params
  })
}
export function yewuyuanUpdateRebate (id, params) {
  return fetch({
    url: `/yewuyuan/updateRebate/${id}`,
    method: 'post',
    params
  })
}
export function yewuyuanStatusUpdate (query) {
  return fetch({
    url: '/yewuyuans/updateStatus',
    method: 'put',
    params: query
  })
}

export function yewuyuanActivityCreate (query) {
  return fetch({
    url: '/promotions/yewuyuan/activity',
    method: 'post',
    params: query,
    refreshToken: true
  })
}

export function yewuyuanActivityList (query) {
  return fetch({
    url: '/promotions/yewuyuan/activity',
    method: 'get',
    params: query
  })
}

export function yewuyuanActivityInfo (id, query) {
  return fetch({
    url: '/promotions/yewuyuan/activity/' + id,
    method: 'get',
    params: query
  })
}

export function yewuyuanActivityStatusUpdate (query) {
  return fetch({
    url: '/promotions/yewuyuan/activitys/updatestatus',
    method: 'put',
    params: query
  })
}

export function yewuyuanActivityItemList (id, query) {
  return fetch({
    url: '/promotions/yewuyuan/activityItemList/' + id,
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderList (query) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryorder',
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderDetail (deliveryid) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryorder/' + deliveryid,
    method: 'get'
  })
}

export function shippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryorder',
    method: 'put',
    params: query
  })
}

export function batchShippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/yewuyuan/batchdeliveryorder',
    method: 'put',
    params: query
  })
}
export function yewuyuanActivityUpdate (id, query) {
  return fetch({
    url: '/promotions/yewuyuan/activity/' + id,
    method: 'put',
    params: query,
    refreshToken: true
  })
}
export function setDeliveryorderMemo (query) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryordermemo',
    method: 'put',
    params: query
  })
}

export function exportDeliveryorder (query) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryorderexport',
    method: 'get',
    params: query
  })
}

export function exportDeliveryorderInfo (query) {
  return fetch({
    url: '/promotions/yewuyuan/deliveryorderinfo/export',
    method: 'get',
    params: query
  })
}

export function exportActivityItemList (query) {
  return fetch({
    url: '/promotions/yewuyuan/activityGoodsExport',
    method: 'get',
    params: query
  })
}

export function getyewuyuanPointList (query) {
  return fetch({
    url: '/promotions/yewuyuan/point',
    method: 'get',
    params: query
  })
}

export function changeyewuyuanPoint (query) {
  return fetch({
    url: '/promotions/yewuyuan/point',
    method: 'post',
    params: query
  })
}

export function processCashWithdrawal (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/yewuyuan/cash_withdrawals/' + cash_withdrawal_id,
    method: 'put',
    params: query
  })
}

export function getCashWithdrawalList (query) {
  return fetch({
    url: '/promotions/yewuyuan/cashWithdrawals',
    method: 'get',
    params: query
  })
}

export function getMerchantTradeList (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/yewuyuan/cashWithdrawal/payinfo/' + cash_withdrawal_id,
    method: 'get',
    params: query
  })
}

export function withdrawExport (query) {
  return fetch({
    url: '/promotions/yewuyuan/cashWithdrawals/export/',
    method: 'get',
    params: query
  })
}

export function batchProcessCashWithdrawal (query) {
  return fetch({
    url: '/yewuyuan/cashWithdrawal/batchpayinfo',
    method: 'put',
    params: query
  })
}

export function getyewuyuanOrderList (query) {
  return fetch({
    url: '/yewuyuan/orders',
    method: 'get',
    params: query
  })
}

export function getyewuyuanOrderDetail (orderId) {
  return fetch({
    url: '/yewuyuan/order/' + orderId,
    method: 'get'
  })
}

// 添加活动商品
export function addActivityGoods (params) {
  return fetch({
    url: `/yewuyuan/items`,
    method: 'post',
    params
  })
}

// 获取社区拼团商品列表
export function getActivityGoods (params) {
  return fetch({
    url: `/yewuyuan/items`,
    method: 'get',
    params
  })
}

// 删除商品
export function deleteActivityGoods (id) {
  return fetch({
    url: `/yewuyuan/item/${id}`,
    method: 'delete'
  })
}

// 修改商品起送量
export function modifyGoodsDeliverNum (params) {
  return fetch({
    url: `/yewuyuan/itemMinDeliveryNum`,
    method: 'post',
    params
  })
}

// 修改商品排序编号
export function modifyGoodsSort (params) {
  return fetch({
    url: `/yewuyuan/itemSort`,
    method: 'post',
    params
  })
}

// 保存社区团购设置
export function modifyActivitySetting (params) {
  return fetch({
    url: `/yewuyuan/activity/setting`,
    method: 'post',
    params
  })
}

// 获取社区团购设置
export function getActivitySetting (params) {
  return fetch({
    url: `/yewuyuan/activity/setting`,
    method: 'get',
    params
  })
}

// 配置字段列表
export function getyewuyuanChiefApplyFields (params) {
  return fetch({
    url: `/yewuyuan/chief/apply_fields`,
    method: 'get',
    params
  })
}

// 创建字段
export function createyewuyuanChiefApplyFields (params) {
  return fetch({
    url: `/yewuyuan/chief/apply_field`,
    method: 'post',
    params
  })
}

// 更新字段的开关
export function modifyFieldSwitch (id, params) {
  return fetch({
    url: `/yewuyuan/chief/apply_field/switch/${id}`,
    method: 'post',
    params
  })
}

// 删除字段
export function deleteyewuyuanField (id) {
  return fetch({
    url: `/yewuyuan/chief/apply_field/${id}`,
    method: 'delete'
  })
}

// 更新字段的内容
export function modifyyewuyuanField (params) {
  const { id } = params
  return fetch({
    url: `/yewuyuan/chief/apply_field/${id}`,
    method: 'post',
    params
  })
}

// 团长申请列表
export function getChiefList (params) {
  return fetch({
    url: `/yewuyuan/chief/apply/list`,
    method: 'get',
    params
  })
}

// 团长申请详情
export function getChiefDetail (id) {
  return fetch({
    url: `/yewuyuan/chief/apply/info/${id}`,
    method: 'get'
  })
}

// 团长申请审批
export function approveChief (apply_id, params) {
  return fetch({
    url: `/yewuyuan/chief/approve/${apply_id}`,
    method: 'post',
    params
  })
}

// 获取小程序码
export function getChiefWxCode (params) {
  return fetch({
    url: `/yewuyuan/chief/apply/wxaCode`,
    method: 'get',
    params
  })
}

//
export function withdrawApply (id, params) {
  return fetch({
    url: `/yewuyuan/cash_withdrawal/${id}`,
    method: 'post',
    params
  })
}

// 提现佣金支付信息
export function getPayInfo (id) {
  return fetch({
    url: `/yewuyuan/cash_withdrawal/payinfo/${id}`,
    method: 'get'
  })
}
