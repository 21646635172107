import fetch from '../utils/fetch'


export function getMemberSignPoint (query) {
  return fetch({
    url: '/point/sign/member',
    method: 'get',
    params: query
  })
}

export function syncOfflineCategory () {
  return fetch({
    url: '/offline/initCat',
    method: 'get'
  })
}

export function syncOfflineCoupon () {
  return fetch({
    url: '/offline/syncCoupon',
    method: 'get'
  })
}
export function syncOfflineShequdian () {
  return fetch({
    url: '/offline/syncShequdian',
    method: 'get'
  })
}
//@zm 刷新线下产品
export function flushOfflineItems () {
  return fetch({
    url: '/offline/flushOfflineItems/',
    method: 'get'
  })
}

//@zm
export function getOrderSyncLogList(query) {
  return fetch({
    url: '/offline/syncLog',
    method: 'get',
    params: query
  })
}

