import fetch from '../utils/fetch'

export function getHaikangInfo (query) {
  return fetch({
    url: '/zitidian/haikang/setting',
    method: 'get',
    params: query
  })
}

export function saveHaikang (params) {
  return fetch({
    url: '/zitidian/haikang/setting',
    method: 'post',
    params: params
  })
}

export function getHaikangShopList (query) {
  return fetch({
    url: '/zitidian/haikang/mengjin/shop',
    method: 'get',
    params: query
  })
}

export function createHaikang (params) {
  return fetch({
    url: '/zitidian/haikang/mengjin/shop',
    method: 'post',
    params: params
  })
}

export function updateHaikang (id, params) {
  return fetch({
    url: '/zitidian/haikang/mengjin/shop/' + id,
    method: 'put',
    params: params
  })
}

export function deleteHaikang (id) {
  return fetch({
    url: '/zitidian/haikang/mengjin/shop/' + id,
    method: 'delete'
  })
}
export function updateDoorHaikang (id,params) {
  return fetch({
    url: '/zitidian/haikang/mengjin/updateDoor/' + id,
    method: 'put',
    params
  })
}

export function getmengjinList (query) {
  return fetch({
    url: '/zitidian/haikang/mengjin/list',
    method: 'get',
    params: query
  })
}

export function getzitidianDetail (id) {
  return fetch({
    url: '/zitidian/' + id,
    method: 'get'
  })
}

export function zitidianCreate (query) {
  return fetch({
    url: '/zitidian',
    method: 'post',
    params: query
  })
}

export function zitidianUpdate (id, query) {
  return fetch({
    url: '/zitidian/' + id,
    method: 'put',
    params: query
  })
}

export function zitidianDelete (id) {
  return fetch({
    url: '/zitidian/' + id,
    method: 'delete'
  })
}

export function getzitidianList (query) {
  return fetch({
    url: '/zitidian/list',
    method: 'get',
    params: query
  })
}
export function getshequdianList (query) {
  return fetch({
    url: '/shequdian/list',
    method: 'get',
    params: query
  })
}
export function getshequdianSetting (query) {
  return fetch({
    url: '/shequdian/lunbo/setting',
    method: 'get',
    params: query
  })
}
export function setshequdianSetting (query) {
  return fetch({
    url: '/shequdian/lunbo/setting',
    method: 'put',
    params: query
  })
}
export function zitidianCheck (id, params) {
  return fetch({
    url: `/zitidian/check/${id}`,
    method: 'post',
    params
  })
}
export function zitidianUpdateRebate (id, params) {
  return fetch({
    url: `/zitidian/updateRebate/${id}`,
    method: 'post',
    params
  })
}
export function zitidianStatusUpdate (query) {
  return fetch({
    url: '/zitidians/updateStatus',
    method: 'put',
    params: query
  })
}

export function getXieYiSetting(query) {
  return fetch({
    url: '/zitidians/setting/get',
    method: 'get',
    params: query
  })
}

export function setXieYiSetting(query) {
  return fetch({
    url: '/zitidians/setting/set',
    method: 'post',
    params: query
  })
}
export function zitidianActivityCreate (query) {
  return fetch({
    url: '/promotions/zitidian/activity',
    method: 'post',
    params: query,
    refreshToken: true
  })
}

export function zitidianActivityList (query) {
  return fetch({
    url: '/promotions/zitidian/activity',
    method: 'get',
    params: query
  })
}

export function zitidianActivityInfo (id, query) {
  return fetch({
    url: '/promotions/zitidian/activity/' + id,
    method: 'get',
    params: query
  })
}

export function zitidianActivityStatusUpdate (query) {
  return fetch({
    url: '/promotions/zitidian/activitys/updatestatus',
    method: 'put',
    params: query
  })
}

export function zitidianActivityItemList (id, query) {
  return fetch({
    url: '/promotions/zitidian/activityItemList/' + id,
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderList (query) {
  return fetch({
    url: '/promotions/zitidian/deliveryorder',
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderDetail (deliveryid) {
  return fetch({
    url: '/promotions/zitidian/deliveryorder/' + deliveryid,
    method: 'get'
  })
}

export function shippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/zitidian/deliveryorder',
    method: 'put',
    params: query
  })
}

export function batchShippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/zitidian/batchdeliveryorder',
    method: 'put',
    params: query
  })
}
export function zitidianActivityUpdate (id, query) {
  return fetch({
    url: '/promotions/zitidian/activity/' + id,
    method: 'put',
    params: query,
    refreshToken: true
  })
}
export function setDeliveryorderMemo (query) {
  return fetch({
    url: '/promotions/zitidian/deliveryordermemo',
    method: 'put',
    params: query
  })
}

export function exportDeliveryorder (query) {
  return fetch({
    url: '/promotions/zitidian/deliveryorderexport',
    method: 'get',
    params: query
  })
}

export function exportDeliveryorderInfo (query) {
  return fetch({
    url: '/promotions/zitidian/deliveryorderinfo/export',
    method: 'get',
    params: query
  })
}

export function exportActivityItemList (query) {
  return fetch({
    url: '/promotions/zitidian/activityGoodsExport',
    method: 'get',
    params: query
  })
}

export function getzitidianPointList (query) {
  return fetch({
    url: '/promotions/zitidian/point',
    method: 'get',
    params: query
  })
}

export function changezitidianPoint (query) {
  return fetch({
    url: '/promotions/zitidian/point',
    method: 'post',
    params: query
  })
}

export function processCashWithdrawal (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/zitidian/cash_withdrawals/' + cash_withdrawal_id,
    method: 'put',
    params: query
  })
}

export function getCashWithdrawalList (query) {
  return fetch({
    url: '/promotions/zitidian/cashWithdrawals',
    method: 'get',
    params: query
  })
}

export function getMerchantTradeList (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/zitidian/cashWithdrawal/payinfo/' + cash_withdrawal_id,
    method: 'get',
    params: query
  })
}

export function withdrawExport (query) {
  return fetch({
    url: '/promotions/zitidian/cashWithdrawals/export/',
    method: 'get',
    params: query
  })
}

export function batchProcessCashWithdrawal (query) {
  return fetch({
    url: '/zitidian/cashWithdrawal/batchpayinfo',
    method: 'put',
    params: query
  })
}

export function getzitidianOrderList (query) {
  return fetch({
    url: '/zitidian/orders',
    method: 'get',
    params: query
  })
}

export function getzitidianOrderDetail (orderId) {
  return fetch({
    url: '/zitidian/order/' + orderId,
    method: 'get'
  })
}

// 添加活动商品
export function addActivityGoods (params) {
  return fetch({
    url: `/zitidian/items`,
    method: 'post',
    params
  })
}

// 获取社区拼团商品列表
export function getActivityGoods (params) {
  return fetch({
    url: `/zitidian/items`,
    method: 'get',
    params
  })
}

// 删除商品
export function deleteActivityGoods (id) {
  return fetch({
    url: `/zitidian/item/${id}`,
    method: 'delete'
  })
}

// 修改商品起送量
export function modifyGoodsDeliverNum (params) {
  return fetch({
    url: `/zitidian/itemMinDeliveryNum`,
    method: 'post',
    params
  })
}

// 修改商品排序编号
export function modifyGoodsSort (params) {
  return fetch({
    url: `/zitidian/itemSort`,
    method: 'post',
    params
  })
}

// 保存社区团购设置
export function modifyActivitySetting (params) {
  return fetch({
    url: `/zitidian/activity/setting`,
    method: 'post',
    params
  })
}

// 获取社区团购设置
export function getActivitySetting (params) {
  return fetch({
    url: `/zitidian/activity/setting`,
    method: 'get',
    params
  })
}

// 配置字段列表
export function getzitidianChiefApplyFields (params) {
  return fetch({
    url: `/zitidian/chief/apply_fields`,
    method: 'get',
    params
  })
}

// 创建字段
export function createzitidianChiefApplyFields (params) {
  return fetch({
    url: `/zitidian/chief/apply_field`,
    method: 'post',
    params
  })
}

// 更新字段的开关
export function modifyFieldSwitch (id, params) {
  return fetch({
    url: `/zitidian/chief/apply_field/switch/${id}`,
    method: 'post',
    params
  })
}

// 删除字段
export function deletezitidianField (id) {
  return fetch({
    url: `/zitidian/chief/apply_field/${id}`,
    method: 'delete'
  })
}

// 更新字段的内容
export function modifyzitidianField (params) {
  const { id } = params
  return fetch({
    url: `/zitidian/chief/apply_field/${id}`,
    method: 'post',
    params
  })
}

// 团长申请列表
export function getChiefList (params) {
  return fetch({
    url: `/zitidian/chief/apply/list`,
    method: 'get',
    params
  })
}

// 团长申请详情
export function getChiefDetail (id) {
  return fetch({
    url: `/zitidian/chief/apply/info/${id}`,
    method: 'get'
  })
}

// 团长申请审批
export function approveChief (apply_id, params) {
  return fetch({
    url: `/zitidian/chief/approve/${apply_id}`,
    method: 'post',
    params
  })
}

// 获取小程序码
export function getChiefWxCode (params) {
  return fetch({
    url: `/zitidian/chief/apply/wxaCode`,
    method: 'get',
    params
  })
}

//
export function withdrawApply (id, params) {
  return fetch({
    url: `/zitidian/cash_withdrawal/${id}`,
    method: 'post',
    params
  })
}

// 提现佣金支付信息
export function getPayInfo (id) {
  return fetch({
    url: `/zitidian/cash_withdrawal/payinfo/${id}`,
    method: 'get'
  })
}
