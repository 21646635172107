// 商品路由
const name = '交易'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/shopadmin/order',
  component: Layout,
  children: [
    {
      path: 'tradenormalorders',
      name: `实物订单`,
      component: () => import('@/view/mall/trade/normalorders/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/trade/order/detail')
        },
        {
          path: 'process/:itemId?',
          component: () => import('@/view/mall/trade/order/process')
        },
        {
          path: 'after-sale/:id?',
          component: () => import('@/view/mall/trade/order/afterSale')
        }
      ]
    },
    {
      path: 'tradepayment',
      name: `交易单`,
      component: () => import('@/view/mall/trade/list')
    },
    {
      path: 'aftersaleslist',
      name: `售后列表`,
      component: () => import('@/view/mall/aftersales/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/detail')
        }
      ]
    },
    {
      path: 'aftersalesrefundlist',
      name: `退款单`,
      component: () => import('@/view/mall/aftersales/refundList'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/refundDetail')
        }
      ]
    },
    {
      path: 'chinaumsdivisionlist',
      name: `分账单`,
      component: () => import('@/view/mall/chinaums/divisionList')
    },
    {
      path: 'shippingtemplates',
      name: `运费模板`,
      component: () => import('@/view/mall/trade/shipping/templates'),
      children: [
        {
          path: 'editor/:templatesId?',
          component: () => import('@/view/mall/trade/shipping/add')
        }
      ]
    },
    {
      path: 'logistics',
      name: `物流公司`,
      component: () => import('@/view/mall/trade/logistics/index'),
      children: [
        {
          path: 'addziti/:id?',
          component: () => import('@/view/mall/trade/logistics/add-ziti'),
          beforeEnter: ({ params, meta }, from, next) => {
            meta.title = params.id ? '编辑自提点' : '新增自提点'
            next()
          }
        }]
    },
    {
      path: 'chajia',
      name: `差价报表`,
      component: () => import('@/view/mall/trade/chajia/index')
    },
    {
      path: 'offlinesynclog',
      name: `接口同步日志`,
      component: () => import('@/view/mall/trade/offlinesynclog/index')
    },
    {
      path: 'zitidian/list',
      name: `自提点列表审核`,
      component: () => import('@/view/mall/trade/zitidian/ziti-list'),
      children: [
        {
          path: 'addziti/:id?',
          component: () => import('@/view/mall/trade/zitidian/add-ziti'),
          beforeEnter: ({ params, meta }, from, next) => {
            meta.title = params.id ? '编辑自提点' : '新增自提点'
            next()
          }
        },
        {
          path: 'xieyi',
          component: () => import('@/view/mall/trade/zitidian/xieyi')
        }
        ]
      },
    {
      path: 'zitidian/tixian',
      name: `提现申请`,
      component: () => import('@/view/mall/trade/zitidian/tixian')
    },
    {
      path: 'zitidian/achievement',
      name: `自提点业绩`,
      component: () => import('@/view/mall/trade/zitidian/achievement')
    },

    {
      path: 'yewuyuan/list',
      name: `业务员列表`,
      component: () => import('@/view/mall/trade/yewuyuan/yewuyuan-list'),
      children: [
        {
          path: 'addyewuyuan/:id?',
          component: () => import('@/view/mall/trade/yewuyuan/add-yewuyuan'),
          beforeEnter: ({ params, meta }, from, next) => {
            meta.title = params.id ? '编辑业务员' : '新增业务员'
            next()
          }
        }
        ]
      },
    {
      path: 'yewuyuan/tixian',
      name: `提现申请`,
      component: () => import('@/view/mall/trade/yewuyuan/tixian')
    },
    {
      path: 'yewuyuan/achievement',
      name: `业务员业绩`,
      component: () => import('@/view/mall/trade/yewuyuan/achievement')
    },

    {
      path: 'dianyuan/list',
      name: `店员列表`,
      component: () => import('@/view/mall/trade/dianyuan/dianyuan-list'),
      children: [
        {
          path: 'adddianyuan/:id?',
          component: () => import('@/view/mall/trade/dianyuan/add-dianyuan'),
          beforeEnter: ({ params, meta }, from, next) => {
            meta.title = params.id ? '编辑店员' : '新增店员'
            next()
          }
        }
        ]
      },
    {
      path: 'dianyuan/achievement',
      name: `店员业绩`,
      component: () => import('@/view/mall/trade/dianyuan/achievement')
    },
    {
      path: 'dianyuan/order',
      name: `店员订单`,
      component: () => import('@/view/mall/trade/dianyuan/order')
    },
    {
      path: 'dianyuan/achievement_dianzhang',
      name: `店长业绩`,
      component: () => import('@/view/mall/trade/dianyuan/achievement_dianzhang')
    },
    {
      path: 'videoshop/list',
      name: `视频号订单列表`,
      component: () => import('@/view/mall/trade/normalorders/listvideoshop'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/trade/order/detail')
        },
        {
          path: 'process/:itemId?',
          component: () => import('@/view/mall/trade/order/process')
        },
        {
          path: 'after-sale/:id?',
          component: () => import('@/view/mall/trade/order/afterSale')
        }
      ]
    },

    {
      path: 'shequdian/list',
      name: `社区提货店铺`,
      component: () => import('@/view/mall/trade/shequdian/shequdian-list')
    },
    {
      path: 'shequdian/achievement',
      name: `提货店铺汇总`,
      component: () => import('@/view/mall/trade/shequdian/shequdian-achievement')
    },
    {
      path: 'shequdian/fendianachievement',
      name: `提货店铺分店表`,
      component: () => import('@/view/mall/trade/shequdian/shequdian-fendianachievement')
    },
    // {
    //   path: 'zitidian/test',
    //   name: `测试`,
    //   component: () => import('@/view/mall/trade/zitidian/test'),
    //   children: [
    //     {
    //       path: 'editor/:marketing_id?',
    //       component: () => import('@/view/mall/trade/zitidian/add-ziti')
    //     }
    //   ]
    // },


    {
      path: 'adapay_trades',
      name: '分账',
      component: () => import('@/view/mall/sub_account/sub_account'),
      children: [
        {
          path: 'detail/:order_id',
          component: () => import('@/view/mall/sub_account/detail')
        }
      ]
    },
    {
      path: 'adapay_cash',
      name: '提现',
      component: () => import('@/view/mall/withdraw/withdraw')
    },
    {
      path: 'subdistrict',
      name: '街道社区配置',
      component: () => import('@/view/mall/subdistrict/list')
    }
  ]
}
