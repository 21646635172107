import fetch from '../utils/fetch'

export function getdianyuanDetail (id) {
  return fetch({
    url: '/dianyuan/' + id,
    method: 'get'
  })
}

export function dianyuanCreate (query) {
  return fetch({
    url: '/dianyuan/create_bind',
    method: 'post',
    params: query
  })
}

export function dianyuanUpdate (id, query) {
  return fetch({
    url: '/dianyuan/update_bind/' + id,
    method: 'put',
    params: query
  })
}

export function dianyuanDelete (id) {
  return fetch({
    url: '/dianyuan/' + id,
    method: 'delete'
  })
}

export function getDianyuanList (query) {
  return fetch({
    url: '/dianyuan/list',
    method: 'get',
    params: query
  })
}
export function getDianZhangList (query) {
  return fetch({
    url: '/dianyuan/dianzhang/list',
    method: 'get',
    params: query
  })
}
export function dianyuanCheck (id, params) {
  return fetch({
    url: `/dianyuan/check/${id}`,
    method: 'post',
    params
  })
}
export function dianyuanUpdateRebate (id, params) {
  return fetch({
    url: `/dianyuan/updateRebate/${id}`,
    method: 'post',
    params
  })
}
export function dianyuanStatusUpdate (query) {
  return fetch({
    url: '/dianyuans/updateStatus',
    method: 'put',
    params: query
  })
}

export function dianyuanRebateCount (query) {
  return fetch({
    url: '/dianyuan/rebate/count',
    method: 'get',
    params: query
  })
}
export function dianyuanOrder (query) {
  return fetch({
    url: '/dianyuan/order/count',
    method: 'get',
    params: query
  })
}

export function rebateCountExport (query) {
  return fetch({
    url: '/dianyuan/rebate/count_export',
    method: 'GET',
    params: query
  })
}

export function dianyuanActivityCreate (query) {
  return fetch({
    url: '/promotions/dianyuan/activity',
    method: 'post',
    params: query,
    refreshToken: true
  })
}

export function dianyuanActivityList (query) {
  return fetch({
    url: '/promotions/dianyuan/activity',
    method: 'get',
    params: query
  })
}

export function dianyuanActivityInfo (id, query) {
  return fetch({
    url: '/promotions/dianyuan/activity/' + id,
    method: 'get',
    params: query
  })
}

export function dianyuanActivityStatusUpdate (query) {
  return fetch({
    url: '/promotions/dianyuan/activitys/updatestatus',
    method: 'put',
    params: query
  })
}

export function dianyuanActivityItemList (id, query) {
  return fetch({
    url: '/promotions/dianyuan/activityItemList/' + id,
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderList (query) {
  return fetch({
    url: '/promotions/dianyuan/deliveryorder',
    method: 'get',
    params: query
  })
}

export function getDeliveryOrderDetail (deliveryid) {
  return fetch({
    url: '/promotions/dianyuan/deliveryorder/' + deliveryid,
    method: 'get'
  })
}

export function shippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/dianyuan/deliveryorder',
    method: 'put',
    params: query
  })
}

export function batchShippingDeliveryorder (query) {
  return fetch({
    url: '/promotions/dianyuan/batchdeliveryorder',
    method: 'put',
    params: query
  })
}
export function dianyuanActivityUpdate (id, query) {
  return fetch({
    url: '/promotions/dianyuan/activity/' + id,
    method: 'put',
    params: query,
    refreshToken: true
  })
}
export function setDeliveryorderMemo (query) {
  return fetch({
    url: '/promotions/dianyuan/deliveryordermemo',
    method: 'put',
    params: query
  })
}

export function exportDeliveryorder (query) {
  return fetch({
    url: '/promotions/dianyuan/deliveryorderexport',
    method: 'get',
    params: query
  })
}

export function exportDeliveryorderInfo (query) {
  return fetch({
    url: '/promotions/dianyuan/deliveryorderinfo/export',
    method: 'get',
    params: query
  })
}

export function exportActivityItemList (query) {
  return fetch({
    url: '/promotions/dianyuan/activityGoodsExport',
    method: 'get',
    params: query
  })
}

export function getdianyuanPointList (query) {
  return fetch({
    url: '/promotions/dianyuan/point',
    method: 'get',
    params: query
  })
}

export function changedianyuanPoint (query) {
  return fetch({
    url: '/promotions/dianyuan/point',
    method: 'post',
    params: query
  })
}

export function processCashWithdrawal (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/dianyuan/cash_withdrawals/' + cash_withdrawal_id,
    method: 'put',
    params: query
  })
}

export function getCashWithdrawalList (query) {
  return fetch({
    url: '/promotions/dianyuan/cashWithdrawals',
    method: 'get',
    params: query
  })
}

export function getMerchantTradeList (cash_withdrawal_id, query) {
  return fetch({
    url: '/promotions/dianyuan/cashWithdrawal/payinfo/' + cash_withdrawal_id,
    method: 'get',
    params: query
  })
}

export function withdrawExport (query) {
  return fetch({
    url: '/promotions/dianyuan/cashWithdrawals/export/',
    method: 'get',
    params: query
  })
}

export function batchProcessCashWithdrawal (query) {
  return fetch({
    url: '/dianyuan/cashWithdrawal/batchpayinfo',
    method: 'put',
    params: query
  })
}

export function getdianyuanOrderList (query) {
  return fetch({
    url: '/dianyuan/orders',
    method: 'get',
    params: query
  })
}

export function getdianyuanOrderDetail (orderId) {
  return fetch({
    url: '/dianyuan/order/' + orderId,
    method: 'get'
  })
}

// 添加活动商品
export function addActivityGoods (params) {
  return fetch({
    url: `/dianyuan/items`,
    method: 'post',
    params
  })
}

// 获取社区拼团商品列表
export function getActivityGoods (params) {
  return fetch({
    url: `/dianyuan/items`,
    method: 'get',
    params
  })
}

// 删除商品
export function deleteActivityGoods (id) {
  return fetch({
    url: `/dianyuan/item/${id}`,
    method: 'delete'
  })
}

// 修改商品起送量
export function modifyGoodsDeliverNum (params) {
  return fetch({
    url: `/dianyuan/itemMinDeliveryNum`,
    method: 'post',
    params
  })
}

// 修改商品排序编号
export function modifyGoodsSort (params) {
  return fetch({
    url: `/dianyuan/itemSort`,
    method: 'post',
    params
  })
}

// 保存社区团购设置
export function modifyActivitySetting (params) {
  return fetch({
    url: `/dianyuan/activity/setting`,
    method: 'post',
    params
  })
}

// 获取社区团购设置
export function getActivitySetting (params) {
  return fetch({
    url: `/dianyuan/activity/setting`,
    method: 'get',
    params
  })
}

// 配置字段列表
export function getdianyuanChiefApplyFields (params) {
  return fetch({
    url: `/dianyuan/chief/apply_fields`,
    method: 'get',
    params
  })
}

// 创建字段
export function createdianyuanChiefApplyFields (params) {
  return fetch({
    url: `/dianyuan/chief/apply_field`,
    method: 'post',
    params
  })
}

// 更新字段的开关
export function modifyFieldSwitch (id, params) {
  return fetch({
    url: `/dianyuan/chief/apply_field/switch/${id}`,
    method: 'post',
    params
  })
}

// 删除字段
export function deletedianyuanField (id) {
  return fetch({
    url: `/dianyuan/chief/apply_field/${id}`,
    method: 'delete'
  })
}

// 更新字段的内容
export function modifydianyuanField (params) {
  const { id } = params
  return fetch({
    url: `/dianyuan/chief/apply_field/${id}`,
    method: 'post',
    params
  })
}

// 团长申请列表
export function getChiefList (params) {
  return fetch({
    url: `/dianyuan/chief/apply/list`,
    method: 'get',
    params
  })
}

// 团长申请详情
export function getChiefDetail (id) {
  return fetch({
    url: `/dianyuan/chief/apply/info/${id}`,
    method: 'get'
  })
}

// 团长申请审批
export function approveChief (apply_id, params) {
  return fetch({
    url: `/dianyuan/chief/approve/${apply_id}`,
    method: 'post',
    params
  })
}

// 获取小程序码
export function getChiefWxCode (params) {
  return fetch({
    url: `/dianyuan/chief/apply/wxaCode`,
    method: 'get',
    params
  })
}

//
export function withdrawApply (id, params) {
  return fetch({
    url: `/dianyuan/cash_withdrawal/${id}`,
    method: 'post',
    params
  })
}

// 提现佣金支付信息
export function getPayInfo (id) {
  return fetch({
    url: `/dianyuan/cash_withdrawal/payinfo/${id}`,
    method: 'get'
  })
}
