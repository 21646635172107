<template>
  <div v-loading="loading">
    <div v-if="type !== 'sale_category' && type !== 'management_category'">
      <el-table :data="list" width="100%" height="420" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column property="id" :label="type === 'other_wxapp' ? 'APPID' : 'ID'" width="120" />
        <el-table-column property="title" :label="type === 'other_wxapp' ? '页面路径' : '标题名称'" />
      </el-table>
      <el-pagination class="pager" :page-size="params.pageSize" layout="prev, pager, next" :total="total"
        @current-change="pageChange" />
    </div>
    <el-table v-else :data="list" height="420" row-key="id" default-expand-all highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" @current-change="handleCurrentChange">
      <el-table-column prop="id" label="ID" width="180" />
      <el-table-column prop="title" label="分类名" />
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
import { VERSION_PLATFORM } from '@/utils'

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    keywords: {
      type: String,
      default: ''
    },
    store: {
      type: Object,
      default: () => {
        return {}
      }
    },
    appid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: [],
      params: {
        pageSize: 10,
        page: 1
      },
      total: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['template_name'])
  },
  watch: {
    type (val) {
      if (val) {
        this.params.page = 1
        this.fetch(val)
      }
    },
    keywords (val) {
      this.params.page = 1
      this.fetch(this.type)
    },
    store (val) {
      this.params.page = 1
      this.fetch(this.type)
    },
    appid (val) {
      this.params.page = 1
      this.fetch(this.type)
    }
  },
  mounted () {
    this.fetch(this.type)
  },
  methods: {
    async fetch (type) {
      console.log('type',type)
      console.log('store',this.store)
      console.log('this.params',this.params)
      console.log('this.$route.query',this.$route.query)
      this.loading = true
      let query = JSON.parse(JSON.stringify(this.params))
      let distributor_id = this.$route.query.distributor_id
      if(this.store?.id){
        distributor_id = this.store.id
      }
      switch (type) {
        case 'sale_category':
          if(VERSION_PLATFORM){
            if (distributor_id) {
            Object.assign(query, {
              distributor_id
            })
           }
          }
          api.goods.getCategory(query).then((res) => {
            let items = []
            res.map((item) => {
              let itemObj = {
                id: item.category_id,
                title: item.category_name
              }
              if (item.children.length) {
                let childs = []
                item.children.map((child) => {
                  let childObj = {
                    id: child.category_id,
                    title: child.category_name
                  }
                  if (child.children.length) {
                    let grands = []
                    child.children.map((grand) => {
                      const grandObj = {
                        id: grand.category_id,
                        title: grand.category_name
                      }
                      grands.push(grandObj)
                    })
                    Object.assign(childObj, { children: grands })
                  }
                  childs.push(childObj)
                })
                Object.assign(itemObj, { children: childs })
              }
              items.push(itemObj)
            })
            this.list = items
            this.loading = false
          })
          break
        case 'management_category':

          if (distributor_id && distributor_id>0) {
            Object.assign(query, {
              distributor_id
            })
          }else{
            Object.assign(query, {
              is_main_category: true
            })
          }
          api.goods.getCategory(query).then((res) => {
            let items = []
            res.map((item) => {
              let itemObj = {
                id: item.category_id,
                title: item.category_name
              }
              if (item.children.length) {
                let childs = []
                item.children.map((child) => {
                  let childObj = {
                    id: child.category_id,
                    title: child.category_name
                  }
                  if (child.children.length) {
                    let grands = []
                    child.children.map((grand) => {
                      const grandObj = {
                        id: grand.category_id,
                        title: grand.category_name
                      }
                      grands.push(grandObj)
                    })
                    Object.assign(childObj, { children: grands })
                  }
                  childs.push(childObj)
                })
                Object.assign(itemObj, { children: childs })
              }
              items.push(itemObj)
            })
            this.list = items
            this.loading = false
          })
          break
        case 'tag':
          Object.assign(query, {
            tag_name: this.keywords
          })
          api.goods.getTagList(query).then(({ list, total_count }) => {
            let _list = []
            list.map((item) => {
              _list.push({
                id: item.tag_id,
                title: item.tag_name
              })
            })
            this.list = _list
            this.total = total_count
            this.loading = false
          })
          break

        default:
      }
    },
    pageChange (val) {
      this.params.page = val
      this.fetch(this.type)
    },
    handleCurrentChange (val) {
      this.$emit('onClick', val)
    }
  }
}
</script>

<style scoped lang="scss">
.pager {
  margin-top: 20px;
}
</style>
