import fetch from '../utils/fetch'

// 美洽配置
export function getVideoshopInfo (query) {
  return fetch({
    url: '/wechat/videoshop',
    method: 'get',
    params: query
  })
}

export function saveVideoshop (params) {
  return fetch({
    url: '/wechat/videoshop',
    method: 'post',
    params: params
  })
}

export function getVideoshopShopList (query) {
  return fetch({
    url: '/wechat/videoshop/shop',
    method: 'get',
    params: query
  })
}

export function createVideoshop (params) {
  return fetch({
    url: '/wechat/videoshop/shop',
    method: 'post',
    params: params
  })
}

export function updateVideoshop (id, params) {
  return fetch({
    url: '/wechat/videoshop/shop/' + id,
    method: 'put',
    params: params
  })
}

export function deleteVideoshop (id) {
  return fetch({
    url: '/wechat/videoshop/shop/' + id,
    method: 'delete'
  })
}


//店铺是否绑定小店
export function getIsBind (params) {
  return fetch({
    url: '/wechat/videoshop/isbind',
    method: 'get'
  })
}

//获取当前分类绑定的视频号小店分类
export function getBindInfo (params) {
  return fetch({
    url: '/wechat/videoshop/bindinfo/' + params.category_id,
    method: 'get'
  })
}

//获取当前店铺的微信视频号小店分类
export function getVideoshopCategory (params) {
  return fetch({
    url: '/wechat/videoshop/category',
    method: 'get'
  })
}

export function editVideoshopCategory (params) {
  return fetch({
    url: '/wechat/videoshop/category/' + params.category_id,
    method: 'get',
    params: params
  })
}

export function saveVideoshopCategory (params) {
  return fetch({
    url: '/wechat/videoshop/category/' + params.category_id,
    method: 'post',
    params: params
  })
}

//商品同步到小店
export function toVideoShop (params) {
  return fetch({
    url: '/wechat/videoshop/goods/add',
    method: 'post',
    params: params
  })
}

export function getOrderList(query) {
  return fetch({
    url: '/wechat/videoshop/orders',
    method: 'get',
    params: query
  })
}
